/* eslint-disable react/prop-types */
import cx from 'classnames';
import './subMenu.scss';
import { Icon } from '@bwoty-web/ui-kit';
import { UI_CHEVRON_RIGHT, UI_CHEVRON_LEFT } from '@bwoty-web/ui-kit/icons';

const SubMenu = ({
  items,
  activeItems,
  setActiveItems,
  content,
  subLevel = 1,
  parentId,
}) => {
  return (
    <div
      className={cx('header-sub-menu', {
        'header-sub-menu--last': activeItems.length === subLevel,
        'header-sub-menu--active':
          subLevel <= activeItems.length &&
          !!activeItems.find((item) => item.id === parentId),
        [`header-sub-menu--level-${subLevel}`]: subLevel,
      })}
    >
      <button
        type="button"
        onClick={() => setActiveItems(activeItems.slice(0, -1))}
        className="header-sub-menu-back-button"
      >
        <Icon path={UI_CHEVRON_LEFT} size="xxs" aria-hidden />
        {`${[content.menuLabel, ...activeItems.map((item) => item.title)]
          .slice(0, subLevel)
          .join(' / ')}`}
      </button>
      <ul>
        {items.map((subLink) => {
          const { link, subLinks, title, id } = subLink;
          const hasSubLinks = subLinks?.length > 0;

          if (hasSubLinks) {
            const isExpanded = activeItems.includes(title);
            return (
              <li
                key={title}
                className={cx('header-sub-menu-item', {
                  'header-sub-menu-item--expanded': isExpanded,
                })}
              >
                <button
                  type="button"
                  onClick={() => {
                    if (isExpanded) {
                      setActiveItems(
                        activeItems.slice(
                          0,
                          activeItems.findIndex((item) => item.id === id),
                        ),
                      );
                    } else {
                      setActiveItems([
                        ...activeItems.slice(0, subLevel),
                        { title, id },
                      ]);
                    }
                  }}
                >
                  {title}
                  <Icon path={UI_CHEVRON_RIGHT} size="xxs" aria-hidden />
                </button>
                <SubMenu
                  items={subLinks}
                  activeItems={activeItems}
                  setActiveItems={setActiveItems}
                  content={content}
                  subLevel={subLevel + 1}
                  parentId={id}
                />
              </li>
            );
          }
          return (
            <li className="header-sub-menu-item" key={link.title}>
              <a href={link?.href?.url}>{link?.title}</a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SubMenu;
